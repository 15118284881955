import React from 'react';
import { useParams,Link  } from 'react-router-dom';
import './ValidaCredencial.css';

const ValidaCredencial = () => {
  const { idCredencial } = useParams();
  
  const imagePath = idCredencial ? `/credenciales/${idCredencial}.jpg` : '';

  // Verifica si la imagen existe
  const [imageExists, setImageExists] = React.useState(true);

  React.useEffect(() => {
    if (idCredencial) {
      const img = new Image();
      img.src = imagePath;
      img.onload = () => setImageExists(true);
      img.onerror = () => setImageExists(false);
    }
  }, [imagePath, idCredencial]);

  if (!idCredencial) {
    return (
      <div className="valida-credencial">
        <h2>Error</h2>
        <p>No se ha proporcionado una credencial válida.</p>
      </div>
    );
  }

  if (!imageExists) {
    return (
      <div className="valida-credencial">
        <h2>Error</h2>
        <p>La credencial no existe o no es válida.</p>
      </div>
    );
  }

  return (    
    <div className="valida-credencial">
      <Link to="/">
        <img src="/logocongregacion.png" alt="Home" className="home-link-image" />
      </Link>
      <h2>Ministro de Culto de la Congregación Cristiana en Chile</h2>      
      <h3>Credencial vigente</h3>
      <img src={imagePath}   className="credencial-image" />
    </div>
  );
};

export default ValidaCredencial;
